<template>
    <LiefengContent>
        <template v-slot:title>菜单管理</template>
        <template v-slot:contentArea>
            <div class="item" v-for="(menu, index) in menuList" :key="menu.id + index">
                <div class="have-child" v-if="menu.subMenus && menu.subMenus.length > 0">
                    <div class="have-name">
                        {{ menu.name }}
                        <img class="img" :src="menu.icon == undefined ? '-' : menu.icon" style="width: 16px; height: 16px" onerror="this.style.display='none';"/>
                    </div>
                    <div class="have-child-child" v-for="submenu in menu.subMenus" :key="submenu.id">
                        <div class="have-child-child-item" v-if="submenu.subMenus && submenu.subMenus.length > 0">
                            <div class="have-child-child-item-child" v-for="smenu in submenu.subMenus" :key="smenu.id">
                                <div>{{ smenu.name }}</div>
                            </div>
                        </div>
                        <div class="have-child-child-child" v-else>
                            <div>{{ submenu.name }}</div>
                        </div>
                    </div>
                </div>
                <div class="none-child">{{ menu.name }}</div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
export default {
    components: {
        LiefengContent,
    },
    data() {
        return {
            menuList: [],
            allMenuList: [],
        }
    },
    created() {
        this.menuList = JSON.parse(window.sessionStorage.getItem("menuList"))
    },
    methods: {},
}
</script>

<style lang="less" scoped>
    .item{
        .have-child{
            border: 1px solid rgba(#ccc, 0.5);
            border-radius: 10px;
            .have-name{
                display: flex;
                align-items: center;
                .img{
                    margin-left: 10px;
                }
            }
        }
        .none-child{
            border: 1px solid blue;
        }
    }

</style>